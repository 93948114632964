import type { FunctionComponent } from 'react';
import { TEXT } from '../../lib/utils/text';
import { Container } from '../layout/grid';
import { Markdown } from '../markdown';
import { Text } from '../text';
import styles from './disclaimers.module.scss';
export const Disclaimers: FunctionComponent<DisclaimersProps> = ({
  defaults,
  starred,
  numbered,
  disclaimersMd
}) => {
  // split on newlines and filter out empty strings
  const starredList = (starred ?? defaults).split(/\n/g).filter(Boolean);
  const numberedList = numbered ? numbered.split(/\n/g).filter(Boolean) : [];
  return <div className={styles['disclaimers-container']} data-sentry-component="Disclaimers" data-sentry-source-file="disclaimers.tsx">
            <Container data-sentry-element="Container" data-sentry-source-file="disclaimers.tsx">
                <ul className={styles.global}>
                    {starredList.map((item, index) => <Markdown typeStyle='body-2xs' tag='li' color='dim' key={`${item}--${index}`} markdown={item} />)}
                </ul>

                {numbered?.length && <ol className={styles.disclaimers}>
                        {numberedList.map((item, index) => <Markdown typeStyle='body-2xs' tag='li' color='dim' key={`${item}--${index}`} markdown={item} />)}
                    </ol>}

                {disclaimersMd && <div className='disclaimersMd'>
                        <Markdown typeStyle='body-2xs' tag='p' color='dim' markdown={disclaimersMd} />
                    </div>}

                <Text typeStyle='body-2xs' tag='p' color='dim' data-sentry-element="Text" data-sentry-source-file="disclaimers.tsx">
                    {TEXT.footer.copyright}
                </Text>
            </Container>
        </div>;
};
export default Disclaimers;
export interface DisclaimersProps {
  defaults: string;
  starred?: string;
  numbered?: string;
  disclaimersMd?: string;
}